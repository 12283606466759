import styled, { css } from 'styled-components';
import {Modal} from "react-bootstrap";

const RubikBold = 'Rubik-Bold';
const BoldFont = 'Rubik-Medium';
const MediumFont = 'Rubik-Regular';

const EngagementTab = styled.div`
  height: auto;
  position: relative;
  bottom: 20px;
  @media (max-width: 500px) {
    padding: 20px 10px;
  }
`;

const CardContainer = styled.div`
  float: left;
  min-height: ${(props) => props.height? '55px': '120px'}
  width: calc((100% - 57px)/4);
  display: inline-block;
  margin-right: 18px;
  background: #fff;
  border-radius: 4px;
  &:last-child {
    margin-right: 0;
  }
  
  @media (max-width: 1200px) and (min-width: 501px) {
    width: calc((100% - 18px)/2);
    margin: 10px 18px 10px 0px;

    &:nth-child(even) {
      margin-right: 0;
    }
  }

  @media (max-width: 600px) {
    width: 100%;
    padding: 0;
    height: 80px;
    margin: 10px 0;
  }
`;

const EmployeeCard = styled.div`
  margin: 0px;
  background-color: white;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: ${({paddingTop}) => paddingTop ? '0px 10px 10px 0px': '2px 10px 0px 10px;'};

  .emp-info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .top-name {
    color: #6F767E;
    font-family: ${RubikBold};
    font-size: 11px;
    margin-left: 5px;
  }
  
  .numberContainer {
    padding-right: 10px;
    display: block;
    float: left; 
    > h3 {
      font-size: 35px;
      font-family: ${BoldFont};
      color: #282c37;
      font-weight: 900;
      margin-top: 25px;
      margin-bottom: 0px;
    }
  }
  
  @media (max-width: 1200px) {
    .top-name{
      font-size: 12px;
      padding-top: 5px;
    }
  }
`;

const NewUserCard = styled(EmployeeCard)`
  padding: ${({padding}) => padding ? padding : '0px' };
  background-color:${({bgColor}) => bgColor ? bgColor : 'unset'};
  border-bottom: ${({borderTop}) => borderTop ? '' : '1px solid #ededed' };
  border-radius: ${({borderTop}) => borderTop ? '6px' : '0px' };
  justify-content:${({justify}) => justify ? 'none' : 'center' }}
  align-items: center;
  > svg{
    width: ${({width}) => width ? width: '5%'};
  }
  
  ${({padding}) => padding && css`
    @media (max-width: 1200px) and (min-width: 760px) {
      padding: 11px 17px;
    }
    @media (max-width: 400px) {
      padding: 11px 30px;
    }
  `}

  height: auto;
  justify-content: space-between;
  .employee-info {
    width: 100%;
    display: flex;
    align-items: center;
  } 
  .user-birthday {
    font-family: ${MediumFont};
    font-size: 16px;
    color: #333333;
    text-transform: capitalize;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    > img {
      align-self: flex-start;
      width: 20px;
      height: 20px;
    }
    > div > p {
      float: left;
    }
  }
  .user-name {
    font-family: ${MediumFont};
    font-size: 14px;
    color: #333333;
    text-transform: capitalize;
    padding-left: 10px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 135px;
    > div: last-child {
      font-size: 14px;
      color: #9EA0A5;
      max-width: 129px;
    }
  }
  .new-user-name{
    text-transform: capitalize;
    padding-left: 10px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    overflow: hidden;

font-family:  Rubik-medium;;
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 16px;
color: #3A3A3A;
margin-right: 10px;
    > div: last-child {
      font-size: 14px;
      color: #9EA0A5;
    }
  }
  }
  
  .birth-date {
     font-size: 14px;
     color: #9EA0A5; 
     margin-right: 5px;
     margin-bottom: 0
   }

`;

const EmployeeGrid = styled.div`
  width: ${({gridWidth}) => gridWidth ? gridWidth: '20%'};
  font-size: ${({textAlign}) => textAlign ? '30px': '12px' };
  text-align: ${({textAlign}) => textAlign ? 'center': 'left'};
  color: ${({color}) => color ? color : 'rgb(62,78,104)'};
  padding-top: ${({paddingTop}) => paddingTop ? '6px': '0px'};
  padding-left: ${({ paddingLeft }) => paddingLeft ? '5': '0px' }
  text-align: ${({ groupActivity }) => groupActivity && 'center'};
  display: ${({ groupActivity }) => groupActivity && 'flex'};
  justify-content: ${({ groupActivity }) => groupActivity && 'center'};
  align-items: ${({ groupActivity }) => groupActivity && 'center'};
  font-weight: ${({fontWeight}) =>  fontWeight && 'normal' };
  background-color: ${({radius,bgColor,opacity}) => bgColor ? bgColor : radius ? '#1E76AB' : opacity ? "0.3" :'0.15'};
  border-radius: ${({radius}) => radius ? '5px' : 'unset'};
  max-height: ${({radius}) => radius ? '35px' : 'none' };

  ${({ employeeGrid }) => employeeGrid && css`
    font-weight: 300;
  `}

  @media (max-width: 1200px) {
    font-size: ${({textAlign}) => textAlign ? '30px': '10px' };
  }

  div{
    > img {
    width: 50px;
    height: 50px;
  }

  }

  > img {
    width: 50px;
    height: 50px;
  }
  > i {
    color: #999999;
    float: right;
    font-size: 17px;
  }
  .company {
    font-weight: normal;
    text-transform: uppercase;
    color: rgb(153,153,153);
    }
  }
  .name {
    font-family: ${MediumFont};
    padding-left: 16px;
    font-size:15px;
    color:#0D4270; 
    text-transform: capitalize;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

const NewPointData = styled.div`
font-family: Rubik;
padding: 4px;
margin-left: 15px;
box-sizing: border-box;
border-radius: 2.56px;
display: inline-block;
font-size: 14px;
text-align: center;
color: ${({ color}) =>  color ? color : '#fff'};
@media (max-width: 760px) {
  width: 35%;
}
  `;
const ProfileData = styled.div`

    text-transform: capitalize;
    padding-left: 14px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-left: 14px;
font-family:  Rubik-medium;
font-style: normal;
font-weight: 500;
font-size: 16px;
color: #0D4270;
    }
    .dcls {
      font-family:  Rubik;
      font-size: 14px;
      color: #9c9c9c;
    }
`;
const PointData = styled.div`
  text-align: center;
   > h4 {
     background-color: none;
     color: #005c87;
     font-family: Rubik-Medium;
     opacity: none;
     font-size: 16px;
   }
   .percentage{
    background-color: none;
     color: #005c87;
     font-family: Rubik-Medium;
     opacity: none;
     font-size: 16px;
     display:flex;
     justify-content:center;
     align-items:center;

   }
 `;
 
const ColorData =  styled.div`
   font-family: ${BoldFont}
   font-size: 14px;
   padding-top: 2px;
   background-color: ${({bgColor}) => bgColor ? 'bgColor' : 'unset' };
   `;

const ThemeGrid =styled.div`
    width: 50px;
    height: 50px;
text-align: ${({textAlign}) => textAlign ? 'right': 'left'};
padding-left: 6px;
padding-top: ${({paddingTop}) => paddingTop ? '6px': '0px'};
text-align: ${({ groupActivity }) => groupActivity && 'center'};
display: ${({ groupActivity }) => groupActivity && 'flex'};
font-weight: ${({fontWeight}) =>  fontWeight && 'normal' };
border-radius: ${({radius}) => radius ? '20px' : 'unset'};
max-height: ${({radius}) => radius ? '35px' : 'none' };
${({ employeeGrid }) => employeeGrid && css`
  font-weight: 300;
`}

@media (max-width: 1200px) {
  font-size: ${({textAlign}) => textAlign ? '30px': '10px' };
}

> i {
  color: #999999;
  float: right;
  font-size: 17px;
}
}
.theme {
  font-family: ${MediumFont};
  font-size:14px;
  color:white; 
  border-bottom:2px solid #e9e8e8;
  text-transform: capitalize;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
  }
`;

const DataThemeGrid =styled.div`
text-align: ${({textAlign}) => textAlign ? 'right': 'left'};
padding-left: 15px;
padding-top: ${({paddingTop}) => paddingTop ? '6px': '0px'};
text-align: ${({ groupActivity }) => groupActivity && 'center'};
display: ${({ groupActivity }) => groupActivity && 'flex'};
font-weight: ${({fontWeight}) =>  fontWeight && 'normal' };
border-radius: ${({radius}) => radius ? '20px' : 'unset'};
max-height: ${({radius}) => radius ? '35px' : 'none' };
${({ employeeGrid }) => employeeGrid && css`
  font-weight: 300;
`}

@media (max-width: 1200px) {
  font-size: ${({textAlign}) => textAlign ? '30px': '10px' };
}

> i {
  color: #999999;
  float: right;
  font-size: 17px;
}
}
.theme {
  font-family: ${MediumFont};
  font-size:14px;
  color:white; 
  border-bottom:2px solid #e9e8e8;
  text-transform: capitalize;
  margin-left: 5px;
;
  }

  .new_theme {
    font-family: ${MediumFont};
    font-size:14px;
    color:white; 
    text-transform: capitalize;
    }
`;

const DataThemeGridNew =styled.div`
text-align: ${({textAlign}) => textAlign ? 'right': 'left'};
padding-left: 6px;
padding-top: ${({paddingTop}) => paddingTop ? '6px': '0px'};
text-align: ${({ groupActivity }) => groupActivity && 'center'};
display: ${({ groupActivity }) => groupActivity ? 'flex' : "block"};
font-weight: ${({fontWeight}) =>  fontWeight && 'normal' };
border-radius: ${({radius}) => radius ? '20px' : 'unset'};
max-height: ${({radius}) => radius ? '35px' : 'none' };
${({ employeeGrid }) => employeeGrid && css`
  font-weight: 300;
`}

@media (max-width: 1200px) {
  font-size: ${({textAlign}) => textAlign ? '30px': '10px' };
}

> i {
  color: #999999;
  float: right;
  font-size: 17px;
}
.new_theme {
  font-family: ${BoldFont};
  font-size:14px;
  margin-left: 6px;
  color:white; 
  text-transform: capitalize;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
  }
`;
const MoreMonthData = styled.div`
 background-color: #EAEAEA;
 border-radius: 0px 0px 6px 6px;
`;
const SeeAll = styled.div`
    width: 100%;
    height: 22px;
    display: flex;
    justify-content: center;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #3A3A3A;
    cursor: pointer;
    padding-top: 4px;
`;
const CommonContainer = styled.div`
  width: 100%;
  height: ${({fitnessHeight}) => fitnessHeight ? '286px' : '100%'};
  background-color:  ${({bgColor}) => bgColor ? bgColor : 'white'}; 
  padding: ${({padding}) => padding ? padding : '10px'};
  border-top: ${({ borderTop }) => borderTop ? '1px solid #e9e8e8' : ''};
  float: ${({float}) => float ? 'left': ''};
  margin-top: 0px;
  margin-bottom: ${({divideCards}) => divideCards ? '20px' : '0'};
  border-radius: ${({divideCards}) => divideCards ? '6px' : '0'};
  font-size: 12px;
  text-transform: uppercase;
  }
  text-align: ${({textAlign}) => textAlign ? 'center': 'left'};
  @media (max-width: 760px) {
    float: ${({float}) => float ? 'left': ''};
    padding: ${({smallPadding}) => smallPadding && smallPadding };

  > div {
    > span {
      > img {
        height: 25px;
        width: 25px;
        margin-right: 10px;
      }
    }
  }
`;

const EngagementContainer = styled.div`
  padding-top: 25px;
  height: auto;
  display: flex;
  width: 100%;
  margin-bottom: ${({marginBottom}) => marginBottom ? '15px': '0px'};
  @media (max-width: 900px) { 
    display: block;
    float: left;
    width: 100%
  }
`;

const SplitEngagementSection = styled.div`
  width: ${({gridWidth}) => gridWidth ? gridWidth: '100%'};
  padding-right: ${({PaddingRight}) => PaddingRight ? '25px': '0px'};
  float: left;
  display: ${({displayNone}) => displayNone && 'none'};
  width: calc(100% / 2 - 12.5px);
  background: #fff;
  border-radius: 6px;
  .initiative-popup{
    width: 100%;
    height: 100%;
  }
  @media (max-width: 1080px) {
    width: ${({ smallWidth }) => smallWidth && smallWidth };
  } 
  @media (max-width: 900px) {
    width: 100%;
    padding-right: 0px;
    margin-bottom: 15px;
    padding:${({ smallPadding }) => smallPadding && smallPadding };
  }
    
  .boxShadow {
    float: left;
    width: 100%;
    .new-user {
    font-family: ${BoldFont};
    font-size: 13px;
    margin-bottom: 10px;
    color: #26262B;
    text-align: left;
    height: 417px;
    
    }
    .challenge-heading {
    font-family: ${BoldFont};
    font-size: 13px;
    margin-bottom: 10px;
    color: #1E76AB;
    text-align: left;
    }
  }
  & > div:nth-child(2) {
    padding-right: 0
  }
`;

const HeaderTitle = styled.div`
  letter-spacing: 0px;
  color:${({color,newColor}) => color ? '#0D4270' : newColor ? '#0D4270' : 'white'};
  font-family: ${BoldFont};
  text-align: left;
  font-size:${({color,newColor}) => color ? '18px' : newColor ? '18px' : '18px'};
  font-style: normal;
   font-weight: 500;
    line-height: 24px;;
    margin-top:${({color}) => color ? '0px;' : '0'};
    margin-left:${({color}) => color ? '-10px;' : '0'};
    margin-bottom:${({newColor}) => newColor ? '15px' : '0'};

  > span {
    color: #59bbd9;
  }
  
  > p {
   margin: 3px 0 0 10px
   text-transform: capitalize;
  } 

  > img {
    width: 19px;
    height: 19px;
    margin-left: 5px;
  }
`;

const ChallengesContainer = styled.div`
  float: ${({noFloat}) => noFloat ? 'none' : 'left'};
  width: 100%;
  padding: ${({noPadding}) => noPadding ? '0px' : '7px 8px 0'};
`;

const TopChallenges = styled.div`
  float: left;
  min-height: 160px;
  width: 100%;
  display: flex;
  text-align: left;
  position: relative;
  
  @media(max-width: 660px) {
    flex-direction: column;
    height: auto;
  }
  
  @media (max-width: 1200px) and (min-width: 761px) {
    flex-direction: column;
    height: auto;
  }
`;

const TopChallengesImage = styled.div`
  width: 30%;
  margin-right: 10px;
  display: inline-block;
  float: left;
  cursor: pointer;
  > img {
    border-radius: 6px;
    width: 100%;
    height: 160px;
  }
  
   @media (max-width: 1200px) and (min-width: 761px) {
    width: 100%;
    margin-right: 0;
    height: auto;
    >img {
      height: 100%
    }
  }
  
  @media (max-width: 660px) {
    width: 100%;
    margin-right: 0;
    height: auto;
    > img {
      height: 100%;
    }
  }
`;

const TopChallengeContent = styled.div`
  width: 35%;
  font-size: 13px;
  display: inline-block;
  float: left;
  
  @media (max-width: 660px) {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    width: 100%;
    float: none;
    padding-left: 15px;
  }
  
  @media (max-width: 1200px) and (min-width: 761px) {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    width: 100%;
    float: none;
    padding-left: 15px;
  }

  .challenge {
    height: 50%;
    padding-top: 7px;
    .title{
      font-size: 15px;
      font-family: ${BoldFont};      
      width: 90%;
      white-space: pre;
      text-overflow: ellipsis;
      text-transform: capitalize;
      overflow: hidden;
      color: #67676A;
    
      @media (max-width: 500px) {
        font-size: 16px;
      }
    }
  }

  .challenge-details {
    float: left;
    font-size: 12px;
    font-weight: normal;
    text-transform: capitalize;
    color: #67676A;
    font-family: ${BoldFont};
    margin-left: 0px;
    @media (max-width: 660px) {
      position: static;
      margin-top: 10px;
    }
    @media (max-width: 1200px) and (min-width: 761px) {
      position: static;
      margin-top: 10px;
    }
  }
  .data {
    line-height: 14px;
    padding-top: 5px;
    margin-left: 0;
    > span {
      color: #67676A;
      font-family: ${MediumFont};
    }
  }
  
  .challenge-remaining {
    font-size: 12px;
    font-weight: normal;
    text-transform: capitalize;
    bottom: 15px;
    margin-left: 0px;
    @media (max-width: 660px) {
      position: static;
      margin-top: 10px;
    }
    @media (max-width: 1200px) and (min-width: 761px) {
      position: static;
      margin-top: 10px;
    }
  }
  
  .challenge-text {
    text-transform: none;
    margin-left: 0;
    padding-top: 50px;
    @media (max-width: 1200px) and (min-width: 761px) {
      margin-bottom: 3px;
      padding-top: 0;
    }
    > span {
      color: #1E76AB;
      font-family: ${MediumFont};
    }
  }
  
  .challenge-info {
    float: left;
    font-size: 13px;
    padding-top: 7px;
    font-family: ${BoldFont};
    color: #67676A;
    text-transform: none;
    @media (max-width: 660px) {
      position: static;
      margin: unset;
      padding: unset;
    }
    @media (max-width: 1200px) and (min-width: 761px) {
      position: static;
      margin: unset;
      padding: unset;
    }
  }
  
  .info-text {
    margin-left: 0;
    float: left;
    width: 100%;
    max-height: 75px;
    padding-top: 5px;
    font-family: ${MediumFont};
    color: #67676A;
    font-size: 12px;
    text-transform: none;
    -webkit-line-clamp: 4;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box !important;
    overflow: hidden;
    @media (max-width: 660px) {
      position: static;
      margin: unset;
      padding: unset;
    }
    @media (max-width: 1200px) and (min-width: 761px) {
      position: static;
      margin: unset;
      padding: unset;
    }
  }
  
  .challenge-user {
    float: left;
    text-transform: capitalize;
    bottom: 5px;
    position: absolute;
    margin-left: 0px;
    @media (max-width: 660px) {
      position: static;
      margin: 10px 15px;
    }
    @media (max-width: 1200px) and (min-width: 761px) {
      position: static;
      margin: 10px 15px;
    }
  }
  
  .user-info {
    display: flex;
    margin-left: 12px;
    > h6 {
      color: #67676A;
      font-size: 15px
      font-family: ${MediumFont};
      opacity: 0.5
      margin-left: 12px;
      text-transform: none;
      margin-top: 0;
    }
    > span {
      margin-left: -12px;
      > img {
        width:30px;
        height: 30px;
        border-radius: 50%;
        border: 2px solid #fff;
      }
    }
    .user-list {
      color: #67676A;
      font-size: 15px
      font-family: ${MediumFont};
      opacity: 0.5
      margin-left: 5px;
      text-transform: none;
      margin-top: 0;
      display: flex;
      justify-content: center;
      align-items: center
    }
    .circle {
      font-family: ${BoldFont}
      color: #fff;
      display: inline-block;
      padding-top: 3px;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: 2px solid #fff;
      text-align: center;
      background-color: #4798D9;
    } 
  }
`;

const ImageContainer = styled.div`
  float: left;
  width: 35px;
  border-radius: 50%;
  margin-right: 10px;

  div{
    > img {
      height: 50px
     width: 50px 
      border-radius: 3px;
  }

  }
  
  > img {
      height: 50px
     width: 50px 
      border-radius: 3px;
  }
`;

const NoData = styled.div`
  width: 100%;
  padding: 10px 0;
  text-align: center;
  color: rgba(0, 92, 135, 1);
  .no_initiative {
    text-transform: none;
     height: 300px;
    width: 100%;
    display: flex;
    justify-content: center;
    .inner_initiative 
    {
    font-size: 18px;
    font-family: 'Rubik';
    font-style: normal;
    font-style: 'Regular';
    line-height: 24px;
    align-items: center;
    display: flex;
    }
  }
  .sub_initiative {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;

const BirthdayContainer = styled.div`
  width: 100%
  float: left;

  padding-left: 0px;
`;

const BirthdayContainerNew = styled.div`
  width: 94%;
  float: left;
  margin-top: 0px;
  position: relative;
  padding-top:${({newMargin}) =>  newMargin ? '12px' : '0px' };
  margin-top: ${({marginTop,newMargin}) => marginTop ? '-13px' : newMargin ? 'none' : '40px' };

  @media(max-width: 1450px) {
    width: 94%;
  }
  
  @media(max-width: 1200px) {
    width: 100%;
    padding-right: 25px;
  }

  @media(max-width: 760px) {
    width: 100%;
    padding-right: 0
  }
  ::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-track {
  background: #eff4f6; 
  width: 8px;
}
::-webkit-scrollbar-thumb {
  background: #619ab5; 
  border-radius: 8px;
  width: 5px;
  height: 45px;
}
`;
const ButtonContainer = styled.div`
    font-size: 14px;
    text-align: center;
    padding: 2px;
    color: #fff;
    background-color: #CCC;
    border-radius: 0 0 6px 6px;
    text-transform: none;
    cursor: pointer;
    font-family: ${MediumFont}
`;
const HeaderContainer = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-radius: 6px;
  > div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    &:nth-child(2) {
      > span {
        font-size: 16px;
        color: ${({setColor}) => setColor ? '#fff' : 'rgb(153,153,153)'};
        > h5 {
          padding-right: ${({setColor}) => setColor ? '10px' : '0px'};
          margin: 0;
        }
      }
    }
  }

  ${({ textAlign }) => textAlign && css`
    height: 52px;
    > div {
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      width: 100%;
    }
  `}

  @media (max-width: 500px) {
    padding: ${({ isLongContainer }) => isLongContainer ? '21px 10 15px' : '17px 10px 11px'};
  }

  ${({ noPadding }) => noPadding && css`
    padding: 21px 0 15px;

    @media (max-width: 500px) {
      padding: 21px 0 15px;
    }
  `}
`;

const StyledHeader = styled(Modal.Header)`
  background-color: #1C4667;
  border-radius: 4px 4px 0px 0px;
  .close {
    margin-top: -2px;
    color: white;
    opacity: 1;
  }
`;

const UpcomingThemeIcon = styled.img`
  width: ${({width}) => width ? width : '50px'};
`;

const ThemeCard = styled.div`
  display: flex;
  margin-top: 15px;
  align-items: center;
  padding: ${({padding}) => padding ? padding : '0px' };
  background-color:${({bgColor}) => bgColor ? bgColor : 'unset'};
  border-bottom: ${({borderTop}) => borderTop ? '' : '1px solid #ededed' };
  min-height: 70px;
  border-radius: 6px;
`;
 
const ActiveCard = styled.div`  
  }
 `;

const VideoCard = styled.div`
   width: 55%;
   display: flex;
   background-color: black;
    float: right;
 `;

const NewCardContainer = styled.div`
height: 225px;
max-width: 399px;
width: calc((100% /2-25px));
margin: 12.5px 0px;
background: #FFFFFF;
border-radius: 6px;
width: calc((100% /2 - 12.5px));
@media (min-width: 1100px) {
}
@media (min-width: 1500px) {
}
 `;

const NewRow1 = styled.div`
  float: left;
  width: 100%
  margin-top: 10px;
  margin-left: 10px;
  
  & > p {
    font-size: 18px;
    float: left;
    margin: 0;
    text-transform: capitalize;
    color: #0D4270;
    padding: 13px 0 0 18px;
    font-family: 'Rubik-Medium';
    font-style: normal;
    font-weight: 500;
}

  }
  
  & > i {
    float: right;
    font-size: 17px;
    color: #999999;
  }
  
  img {
    width: 50px;
    height: 50px;
    float: right;
  }
  .newData {
     display: flex;
    justify-content: flex-start;
    font-size: 14px;
    justify-content: center;
    text-align: center
    line-height: 15px;
    color: #3a3a3a;
  }
`;

const BodyPart = styled.div`
 width: 100%;
 background-color: white;
 border-radius: 6px;
 display: flex;
 .image_cls {
  width: 40%;

  & > img {
    width: 125px;
    height: 100%;
    border-radius: 6px 0px 0px 0px;
}
    }
 }
 .content_cls {
  width: 100%;
  background: linear-gradient(90deg, #006FBC -1.11%, #7F43FF 97.46%);
  height: 102px;
  border-radius: 0 6px 0 0;
  display: flex;
   & >p {
   font-family: Rubik-Medium;
   font-style: normal;
   font-weight: 500;
   font-size: 16px;
   line-height: 19px;
   color: white;
   padding-top: 0px;
   padding-left: 14px;
  text-transform: capitalize;
  display: flex;
  margin: auto;
  margin-left: 0px;
 }

.Arrow_Img {
     display: flex;
    justify-content: flex-end;
    margin-right: 10px;
    margin-top: -30px;
& > img {
   width: 11px;
 }

}
 }
`;

const ContentBody = styled.div`
width: 100%;
background: linear-gradient(90deg, #00B1BC -1.11%, #75D9A3 97.46%);
height: 102px;
border-radius: 0 6px 0 0;
display: flex;
 & >p {
 font-family: Rubik-Medium;
 font-style: normal;
 font-weight: 500;
 font-size: 16px;
 line-height: 19px;
 color: white;
 padding-top: 0px;
 padding-left: 14px;
text-transform: capitalize;
display: flex;
margin: auto;
margin-left: 0px;
`;
   
const NewTotalCount = styled.div`
    font-size: 72px;
    text-align: center;
    font-family: ${BoldFont};
    color: ${({color}) => color ? color : '#3e4e68'};
    color: ${({ bgcolor }) =>  bgcolor ? bgcolor : '#fff'};
`;

const NewCardDashboard = styled.div`
  float: right;
  max-width: 823px;
  width: 70%;
  display: flex;
  margin-top: -460px;
  flex-wrap: wrap;
  align-item: center;
  justify-content: space-between;
  @media (max-width: 1225px) and (min-width: 501px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
  }
  @media (min-width: 1100px){
    max-width: 785px;
    width: 70%;
  }
  @media (min-width: 1500px){
    max-width: 823px;
    width: 70%;
  }
  @media (max-width: 500px) {
    padding: 0 10px 20px;
  }
`;
const NewImgContainer = styled.div`
   width: 100%;
   height: 7px;
   margin-top: 10px;
}
div{

  > img {
    width: 100%;
    height: auto; 
    margin: auto;
       }

}
    
     > img {
    width: 100%;
    height: auto; 
    margin: auto;
       }
`;

const ColorLine = styled.div`
 width: 100%;
 height:100%;
 background-color: ${({ bgcolor}) =>  bgcolor ? bgcolor : '#fff'};
 border-radius: ${({radius}) => radius ? '11px' : 'unset'};
`;
 
const ChallengeCard = styled.div`
 width: 100%;
 margin: 30px;
 border-radius: 6px;
 margin-left: 0px;
 margin-top: 10px;
 padding-right: 25px;
 cursor: pointer;
}
.date_data {
  margin-top: -32px;
  display: flex;
  -webkit-box-pack: end;
  justify-content: flex-end;
  font-size: 15px;
  font-family: 'Rubik-Medium';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  margin-right: 10px;
  color: #0D4270;
}

`;

const DaysData  = styled.div`
    background-color: white;
    height: 42px;
    margin-top: -10px;
    border-radius: 0 0 6px 6px;

 > h4 {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 34px;
  color: #0D4270;
  padding-left: 50px;
  border-radius: 6px;
  padding-left: 92px;
  position: relative;
  top: 4px;
 }
  > p {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 34px;
    color: #0D4270;
    padding-left: 50px;
    border-radius: 6px;
  }

  .user-info {
    display: flex;
    margin-left: 0;
    padding-left: 15px;
    > h6 {
      color: #67676A;
      font-size: 15px
      font-family: ${MediumFont};
      opacity: 0.5
      margin-left: 5px;
      text-transform: none;
      margin-top: 0;
    }
    > span {
      margin-left: -12px;
      margin-top: 4px;
      > img {
        width:30px;
        height: 30px;
        border-radius: 100%;
        border: 3px solid #fff;
        margin-top: -103px;
      }
    }

  .circle {
    font-family: Rubik;
    font-size: 12px;
    color: rgb(255, 255, 255);
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    width: auto;
    height: 30px;
    min-width: 30px;
    padding: 8px;
    text-align: center;
    background: linear-gradient(
90deg, rgb(0, 111, 188) -1.11%, rgb(127, 67, 255) 97.46%);
    border: 3px solid rgb(255, 255, 255); 
    border-radius: 15px;
    position: relative;
    bottom: 43px;
  }

  .user-list {
    color: #67676A;
    font-size: 13px;
    font-family: ${MediumFont};
    opacity: 0.5
    margin-left: 5px;
    text-transform: none;
    margin-top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  } 
`;
const ChallengeButton  = styled.div`
margin-top: 25px;
width: ${({ width }) =>  width ? width : '96%'};
display: flex;
justify-content: center; 
padding: 13px;
margin-left: 25px;
margin-right: 25px;
height: 50px;
background-color: #005c87;
border-radius: 3px;
ont-family: Rubik;
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 24px;
text-align: centre;
color: #FFFFFF;
cursor: pointer;

${({ disabled }) => disabled === true && css`
background: #b3cfdb;
color: white;
cursor: not-allowed !important;
`}
 `; 


const ConnectDevice = styled.div`
width: 100%;
> button {
   width: 94%;
  height: 50px;
  font-family: Rubik;
  font-style: normal;
   font-weight: 500;
    font-size: 18px;
    line-height: 24px;
  background: ${({ bgcolor }) =>  bgcolor ? bgcolor : 'linear-gradient(90deg, #006FBC -1.11%, #7F43FF 97.46%)'};
  border-radius: 3px;
  color: #FFF;
  padding: 3px;
  border: none;
  display: flex;
  justify-content: stretch;
  outline: none;
  margin-bottom: ${({ margin }) =>  margin ? '15px' : ''};
  > img {
    width: 44px;
    height: 44px;
    margin-right: 0px;
    float: left;
  }
  > div{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    > span {
      font-family: Rubik-Medium;
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 24px;
    }
  }
}
@media(max-width: 766px){
  > button {
    > img {
      width: 8%;
    }
    > div {
      justify-content: center;
      align-items: center;
      padding-left: 0%;
    }
  }
}
@media(max-width: 500px){
  > button {
    > img {
      width: 12%;
    }
    > div {
      justify-content: center;
      align-items: center;
      padding-right: 25%;
      padding-left: 14%;
    }
  }
}
`;
const NewConnectDevice = styled.div`
width: 100%;
> button {
  width: 94%;
  height: 50px;
  font-family: Rubik;
  font-style: normal;
   font-weight: 500;
    font-size: 18px;
    line-height: 24px;
  background: linear-gradient(90deg,#00B1BC -1.11%,#75D9A3 97.46%);
  border-radius: 3px;
  color: #FFF;
  padding: 3px;
  border: none;
  display: flex;
  justify-content: stretch;
  outline: none;
  > img {
    width: 44px;
    height: 44px;
    margin-right: 0px;
    float: left;
  }
  > div{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    > span {
      padding-right: 38px;
      font-family: Rubik-Medium;
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 24px;
    }
  }
}
@media(max-width: 766px){
  > button {
    > img {
      width: 8%;
    }
    > div {
      justify-content: center;
      align-items: center;
      padding-left: 0%;
    }
  }
}
@media(max-width: 500px){
  > button {
    > img {
      width: 12%;
    }
    > div {
      justify-content: center;
      align-items: center;
      padding-right: 25%;
      padding-left: 14%;
    }
  }
}
`;

const HeaderContainerNew = styled.div`
  width: 100%;
  padding: 25px 0 5px 10px;
  border-bottom:  ${({ borderBottom }) => borderBottom ? 'none' : '1px solid #ededed'};
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background-color: ${({setColor}) => setColor ? "rgba(0, 47, 71, 1)" : 'w'};
  border-top-left-radius: ${({setColor}) => setColor ? '5px' : '0'};
  border-top-right-radius: ${({setColor}) => setColor ? '5px' : '0'};
  padding: 25px 0 5px 10px;
  border-bottom-left-radius : ${({ modalHeader}) => modalHeader ? '5px' : '0'};
  border-bottom-right-radius: ${({ modalHeader}) =>  modalHeader ? '5px' : '0'};
  > div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    &:nth-child(2) {
      > span {
        font-size: 16px;
        color: ${({setColor}) => setColor ? '#fff' : 'rgb(153,153,153)'};
        > h5 {
          padding-right: ${({setColor}) => setColor ? '10px' : '0px'};
          margin: 0;
        }
      }
    }
  }

  ${({ textAlign }) => textAlign && css`
    height: 52px;
    > div {
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      width: 100%;
    }
  `}

  @media (max-width: 500px) {
    padding: ${({ isLongContainer }) => isLongContainer ? '21px 10 15px' : '17px 10px 11px'};
  }

  ${({ noPadding }) => noPadding && css`
    padding: 21px 0 15px;

    @media (max-width: 500px) {
      padding: 21px 0 15px;
    }
  `}
`;


const InitiativeButton = styled.div`
width: 305px;
height: 50px;
background-color: rgba(253, 113, 117, 1);
justify-content: center;
display: flex;
border-radius: 5px;
 > div {
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: capitalize;
  font-family: 'Rubik';
  font-size: 18px;
  line-height: 24px;
  font-style: normal;
  color: #ffff;
 }
`;

const RightButton = styled.div`
width: 100%;
display: flex;
justify-content : flex-end;
height: 0px;
position: relative;
bottom 25px;
z-index: 5;
>img{
  width: 20px;
  height: 20px;
  margin-right: 15px;
  z-index: 7;
}
`;

const CommonContainerV2 = styled.div`
  width: 100%;
  background: #fff;
`;

const NoDataV2 = styled.div`
.data{
  font-family: Rubik;
  font-size: 18px;
  line-height: 24px;
  color:#0D4270;
  padding: 90px 0 100px 0px;
  text-align: center;
}
.buttonContainer{
  width: 100%;
  display:flex;
  justify-content: center;
  padding-bottom: 28px;
}
`;

const InitiativeButtonV2 = styled.div`
width: 305px;
height: 50px;
background-color: rgba(253, 113, 117, 1);
justify-content: center;
display: flex;
border-radius: 5px;
 > div {
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: capitalize;
  font-family: 'Rubik-Medium';
  font-size: 18px;
  line-height: 24px;
  font-style: normal;
  color: #ffff;
  cursor: pointer;
 }
`;

const InitiativeButtonV2New = styled.div`
width: 305px;
height: 50px;
background-color: white;
justify-content: center;
display: flex;
border: 1px solid #005c87;
border-radius: 6px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: capitalize;
  font-family: 'Rubik-Medium';
  font-size: 18px;
  line-height: 24px;
  font-style: normal;
  color: #005c87;
  cursor: pointer;
 &:hover{
  background:#005c87;
  color:white;
  border: none;
 }
`;

const ButtonWrapper = styled.div`
width: 100%;
display:flex;
justify-content: center;
padding-bottom: 25px;
position: ${({ position}) =>  position ? 'relative' : ''};
top: ${({top}) =>  top ? top : '0'};
`;

const CardWrapper = styled.div`
width: 100%;
`;

const SeeAllButton = styled.div`
width: 100%;
height: 22px;
background: rgba(234, 234, 234, 1);
cursor: pointer;
border-radius: 0 0 6px 6px;

font-family: Rubik;
font-size: 12px;
line-height: 16px;
color: #0D4270;

display: flex;
justify-content: center;
align-items: center;
`;

const EventCard = styled.div`
width: calc(100% - 25px);
display:block;
margin-bottom:15px;
.wrapper{
  width:100%;
  display:flex;
  border-radius:6px;
  height:102px;
  .image{
    width:40%;
    >img{
      height:102px;
      width:100%;
    }
  }
  .details{
    background: linear-gradient(90deg,#00B1BC -1.11%,#75D9A3 97.46%);
    display: flex;
    font-family: Rubik-Medium;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: white;
    padding-top: 0px;
    padding-left: 14px;
    text-transform: capitalize;
    display: flex;
    margin: auto;
    margin-left: 0px;
    height: 100%;
    width: 60%;
    align-items: center;
  }
}
.eventTimings{
  height: 42px;
  display:flex;
  background:white;
  .participant{
    width:92px;
    padding-left: 20px;
    display:flex;
    >span{
      margin: auto;
      margin-left: -16px;
      margin-right: 0px;
      display:flex;
      >img{
        width: 30px;
        height: 30px;
        border-radius: 100%;
        border: 3px solid rgb(255, 255, 255);
      }
    }
    .circle{
      font-family: Rubik;
      font-size: 12px;
      color: rgb(255, 255, 255);
      display: flex;
      align-items: center;
      justify-content: center;
      height: 30px;
      padding: 8px;
      text-align: center;
      background: linear-gradient(90deg, rgb(0, 111, 188) -1.11%, rgb(127, 67, 255) 97.46%);
      border: 3px solid rgb(255, 255, 255);
      border-radius: 15px;
      margin: auto;
      margin-left: -16px;
    }
  }
  .days{
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 34px;
    color: rgb(13, 66, 112);
    border-radius: 6px;
    align-items: center;
    display: flex;
  }
  .date{
    display: flex;
    justify-content: flex-end;
    font-family: Rubik-Medium;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    margin:auto;
    margin-right: 10px;
    color: rgb(13, 66, 112);
  }
}
`;

const ViewDetailsBtn = styled.div`
  width: 369px;
  height: 40px;
  border-radius: 3px;
  border:1px solid #005c87;
  color:#005c87;
  background: white;
  font-family: rubik-medium;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover{
    background: #005c87;
    color:white;
    cursor: pointer;
  }
`
const CalendarContainer = styled.div`
  width: 331px;
  height: 343px;
  position: absolute;
  box-shadow: 0px 8px 25px 0px #0D427040;
  right:25px;
  top: 75px;
  background-color: white;
  border-radius: 6px;
  @media (min-width: 1550px) {
    width: 331px;
    height: 343px;
    position: absolute;
    right:25px;
    top: 75px;
  }
  @media (max-width: 1450px) and (min-width: 1300px) {
    width: 331px;
    height: 343px;
    position: absolute;
    right:25px;
    top: 75px;
  }
  @media (max-width: 1250px) {
    width: 331px;
    height: 343px;
    position: absolute;
    right:25px;
    top: 75px;
  }
  .react-calendar {
    width: 331px;
    height: 343px;
    justify-content: flex-start;

    .react-calendar__navigation {
      display: flex;
      margin: 0px 10px 10px 15px;
      padding: 10px 20px;
      height: 15%;
      background: #f2f7f9;
      border-radius: 6px;
      align-items: center;
      justify-content: space-between;

      button.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button {
        border: none;
        background-color: white;
        display: none;
      }
      button.react-calendar__navigation__arrow.react-calendar__navigation__prev-button {
        border: none;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: #f2f7f9;
        color: #005c87;
        font-size: 25px;

        &:focus {
          outline: none;
        }
        &:disabled{
          color:#aac9d7
        }
      }
      button.react-calendar__navigation__arrow.react-calendar__navigation__next-button {
        border: none;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: #f2f7f9;
        color: #005c87;
        font-size: 25px;

        &:focus {
          outline: none;
        }
        &:disabled{
          color:#aac9d7
        }
      }
      button.react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
        border: none;
        background-color: white;
        display: none;
      }
      .react-calendar__navigation__label {
        border: none;
        background-color: #f2f7f9;
        border-radius: 24px;
        max-width: 200px;
        min-height: 30px;

        &:focus {
          outline: none;
        }
        span.react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
          font-size: 18px;
          font-family: "Rubik-Medium";
          background: #005c87;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }
    .react-calendar__viewContainer {
      padding: 0px 10px 10px 10px;
      width: 100%;
      height: 215px;

      @media (max-width: 1250px) {
        padding: 0px;
      }

      .react-calendar__month-view {
        width: 100%;
        height: 100%;
        background-color: #faf9f4;

        .react-calendar__month-view__weekdays {
          .react-calendar__tile.react-calendar__tile--active.react-calendar__month-view__weekdays__weekday {
            border-bottom: 4px solid #000;
          }
          .react-calendar__month-view__weekdays__weekday {
            padding:15px 9px 10px 9px;
            ${"" /* border-bottom: 4px solid #AFAFAF; */}
            text-align: center;
            abbr[title] {
              border-bottom: none;
              text-decoration: none;
              color: #005c87;
            }
          }
        }
        .react-calendar__month-view__days {
          margin-top: 8px;
          justify-content: space-evenly;
          background-color: #faf9f4;
          height: 100%;
          width: 100%;
          padding: 0px 9px;
          column-gap: 18px;

          button.react-calendar__tile.react-calendar__tile--now.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day,
          button.react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day {
            background: #005c87;
            flex-basis: unset !important;
            max-width: unset !important;
            overflow: unset !important;
            border: none;
            border-radius: 50%;
            width: 24px;
            height: 24px;
            /* margin: 3px 0; */
            display: flex;
            justify-content: center;
            align-items: center;
            &:focus {
              outline: none;
            }

            > abbr {
              font-size: 13px;
              line-height: 19px;
              color: #fff;
              text-align: center;
              font-family: Rubik;
              font-size: 14px;
            }

            @media (min-width: 1550px) {
              margin: 3px 0;
            }
            @media (max-width: 1450px) and (min-width: 1300px) {
              margin: 5px 0;
            }
            @media (max-width: 1250px) {
              min-height: 33px;
              width: 33px;
              margin: 14px;
            }
            @media (max-width: 1223px) {
              margin: 13px;
            }
            @media (max-width: 1195px) {
              margin: 12px;
            }
            @media (max-width: 1167px) {
              margin: 11px;
            }
            @media (max-width: 1059px) {
              margin: 10px;
            }
            @media (max-width: 1046px) {
              margin: 9px;
            }
            @media (max-width: 1003px) {
              margin: 8px;
            }
            @media (max-width: 975px) {
              margin: 7px;
            }
            @media (max-width: 950px) {
              margin: 15px;
            }
            @media (max-width: 931px) {
              margin: 14px;
            }
            @media (max-width: 903px) {
              margin: 13px;
            }
            @media (max-width: 875px) {
              margin: 12px;
            }
            @media (max-width: 847px) {
              margin: 11px;
            }
            @media (max-width: 819px) {
              margin: 10px;
            }
            @media (max-width: 791px) {
              margin: 9px;
            }
            @media (max-width: 763px) {
              margin: 8px;
            }
            @media (max-width: 735px) {
              margin: 7px;
            }
            @media (max-width: 707px) {
              margin: 6px;
            }
            @media (max-width: 688px) {
              margin: 28px;
            }
            @media (max-width: 642px) {
              margin: 27px;
            }
            @media (max-width: 628px) {
              margin: 25px;
            }
            @media (max-width: 600px) {
              margin: 24px;
            }
            @media (max-width: 586px) {
              margin: 23px;
            }
            @media (max-width: 572px) {
              margin: 22px;
            }
            @media (max-width: 559px) {
              margin: 21px;
            }
            @media (max-width: 544px) {
              margin: 20px;
            }
            @media (max-width: 530px) {
              margin: 19px;
            }
            @media (max-width: 516px) {
              margin: 18px;
            }
            @media (max-width: 502px) {
              margin: 17px;
            }
            @media (max-width: 488px) {
              margin: 16px;
            }
            @media (max-width: 474px) {
              margin: 15px;
            }
            @media (max-width: 460px) {
              margin: 14px;
            }
            @media (max-width: 446px) {
              margin: 13px;
            }
            @media (max-width: 432px) {
              margin: 12px;
            }
            @media (max-width: 418px) {
              margin: 11px;
            }
            @media (max-width: 404px) {
              margin: 10px;
            }
            @media (max-width: 390px) {
              margin: 9px;
            }
            @media (max-width: 376px) {
              margin: 8px;
            }
            @media (max-width: 362px) {
              margin: 7px;
            }
            @media (max-width: 348px) {
              margin: 6px;
            }
            @media (max-width: 334px) {
              margin: 5px;
            }
            @media (max-width: 320px) {
              margin: 4px;
            }
          }
          ${({ bgColor }) =>
    bgColor &&
            css`
              button.react-calendar__tile.react-calendar__tile--active {
                background: linear-gradient(
                  90deg,
                  #006fbc -1.11%,
                  #7f43ff 97.46%
                ) !important;
                color: white !important;
                border: none !important;
                display: flex;
                justify-content: center;
                align-items: center;

                > abbr {
                  font-size: 13px !important;
                  line-height: 19px;
                  color: #fff !important;
                  text-align: center;
                  font-family: Rubik;
                }
              }
            `}

          button.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend {
            background-color: white;
            flex-basis: unset !important;
            max-width: unset !important;
            overflow: unset !important;
            width: 24px;
            height: 24px;
            border: none;
            background: #f8f8f8;
            border-radius: 3px;
            /* margin: 3px 0; */
            display: flex;
            justify-content: center;
            align-items: center;
            > abbr {
              font-family: "Rubik";
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 16px;
              text-align: center;
              color: #005c87;
            }
          }

          button.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth {
            margin: 0;
            background: none;
            border: none;

            > abbr {
              display: none;
            }

            &:focus {
              outline: none;
            }

            @media (min-width: 1550px) {
              margin: 3px 0;
            }
            @media (max-width: 1450px) and (min-width: 1300px) {
              margin: 5px 0;
            }
            @media (max-width: 1250px) {
              min-height: 33px;
              width: 33px;
              margin: 14px;
            }
            @media (max-width: 1223px) {
              margin: 13px;
            }
            @media (max-width: 1195px) {
              margin: 12px;
            }
            @media (max-width: 1167px) {
              margin: 11px;
            }
            @media (max-width: 1059px) {
              margin: 10px;
            }
            @media (max-width: 1046px) {
              margin: 9px;
            }
            @media (max-width: 1003px) {
              margin: 8px;
            }
            @media (max-width: 975px) {
              margin: 7px;
            }
            @media (max-width: 950px) {
              margin: 15px;
            }
            @media (max-width: 931px) {
              margin: 14px;
            }
            @media (max-width: 903px) {
              margin: 13px;
            }
            @media (max-width: 875px) {
              margin: 12px;
            }
            @media (max-width: 847px) {
              margin: 11px;
            }
            @media (max-width: 819px) {
              margin: 10px;
            }
            @media (max-width: 791px) {
              margin: 9px;
            }
            @media (max-width: 763px) {
              margin: 8px;
            }
            @media (max-width: 735px) {
              margin: 7px;
            }
            @media (max-width: 707px) {
              margin: 6px;
            }
            @media (max-width: 688px) {
              margin: 28px;
            }
            @media (max-width: 642px) {
              margin: 27px;
            }
            @media (max-width: 628px) {
              margin: 25px;
            }
            @media (max-width: 600px) {
              margin: 24px;
            }
            @media (max-width: 586px) {
              margin: 23px;
            }
            @media (max-width: 572px) {
              margin: 22px;
            }
            @media (max-width: 559px) {
              margin: 21px;
            }
            @media (max-width: 544px) {
              margin: 20px;
            }
            @media (max-width: 530px) {
              margin: 19px;
            }
            @media (max-width: 516px) {
              margin: 18px;
            }
            @media (max-width: 502px) {
              margin: 17px;
            }
            @media (max-width: 488px) {
              margin: 16px;
            }
            @media (max-width: 474px) {
              margin: 15px;
            }
            @media (max-width: 460px) {
              margin: 14px;
            }
            @media (max-width: 446px) {
              margin: 13px;
            }
            @media (max-width: 432px) {
              margin: 12px;
            }
            @media (max-width: 418px) {
              margin: 11px;
            }
            @media (max-width: 404px) {
              margin: 10px;
            }
            @media (max-width: 390px) {
              margin: 9px;
            }
            @media (max-width: 376px) {
              margin: 8px;
            }
            @media (max-width: 362px) {
              margin: 7px;
            }
            @media (max-width: 348px) {
              margin: 6px;
            }
            @media (max-width: 334px) {
              margin: 5px;
            }
            @media (max-width: 320px) {
              margin: 4px;
            }
          }

          button.react-calendar__tile.react-calendar__month-view__days__day {
            background-color: white;
            flex-basis: unset !important;
            max-width: unset !important;
            overflow: unset !important;
            width: 24px;
            height: 24px;
            border: none;
            background: #f8f8f8;
            border-radius: 3px;
            margin: 3px 0;
            display: flex;
            justify-content: center;
            align-items: center;
            > abbr {
              font-family: "Rubik";
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 16px;
              text-align: center;
              color: #005c87;
            }

            &:focus {
              outline: none;
            }

            @media (min-width: 1550px) {
              margin: 3px 0;
            }
            @media (max-width: 1450px) and (min-width: 1300px) {
              margin: 5px 0;
            }
            @media (max-width: 1250px) {
              min-height: 33px;
              width: 33px;
              margin: 14px;
            }
            @media (max-width: 1223px) {
              margin: 13px;
            }
            @media (max-width: 1195px) {
              margin: 12px;
            }
            @media (max-width: 1167px) {
              margin: 11px;
            }
            @media (max-width: 1059px) {
              margin: 10px;
            }
            @media (max-width: 1046px) {
              margin: 9px;
            }
            @media (max-width: 1003px) {
              margin: 8px;
            }
            @media (max-width: 975px) {
              margin: 7px;
            }
            @media (max-width: 950px) {
              margin: 15px;
            }
            @media (max-width: 931px) {
              margin: 14px;
            }
            @media (max-width: 903px) {
              margin: 13px;
            }
            @media (max-width: 875px) {
              margin: 12px;
            }
            @media (max-width: 847px) {
              margin: 11px;
            }
            @media (max-width: 819px) {
              margin: 10px;
            }
            @media (max-width: 791px) {
              margin: 9px;
            }
            @media (max-width: 763px) {
              margin: 8px;
            }
            @media (max-width: 735px) {
              margin: 7px;
            }
            @media (max-width: 707px) {
              margin: 6px;
            }
            @media (max-width: 688px) {
              margin: 28px;
            }
            @media (max-width: 642px) {
              margin: 27px;
            }
            @media (max-width: 628px) {
              margin: 25px;
            }
            @media (max-width: 600px) {
              margin: 24px;
            }
            @media (max-width: 586px) {
              margin: 23px;
            }
            @media (max-width: 572px) {
              margin: 22px;
            }
            @media (max-width: 559px) {
              margin: 21px;
            }
            @media (max-width: 544px) {
              margin: 20px;
            }
            @media (max-width: 530px) {
              margin: 19px;
            }
            @media (max-width: 516px) {
              margin: 18px;
            }
            @media (max-width: 502px) {
              margin: 17px;
            }
            @media (max-width: 488px) {
              margin: 16px;
            }
            @media (max-width: 474px) {
              margin: 15px;
            }
            @media (max-width: 460px) {
              margin: 14px;
            }
            @media (max-width: 446px) {
              margin: 13px;
            }
            @media (max-width: 432px) {
              margin: 12px;
            }
            @media (max-width: 418px) {
              margin: 11px;
            }
            @media (max-width: 404px) {
              margin: 10px;
            }
            @media (max-width: 390px) {
              margin: 9px;
            }
            @media (max-width: 376px) {
              margin: 8px;
            }
            @media (max-width: 362px) {
              margin: 7px;
            }
            @media (max-width: 348px) {
              margin: 6px;
            }
            @media (max-width: 334px) {
              margin: 5px;
            }
            @media (max-width: 320px) {
              margin: 4px;
            }
          }
        }
      }
      .react-calendar__year-view {
        width: 100%;
        height: 100%;

        .react-calendar__year-view__months {
          height: 100%;
          width: 100%;
          justify-content: space-evenly;

          button.react-calendar__tile.react-calendar__tile--now.react-calendar__tile--hasActive.react-calendar__year-view__months__month {
            border: none;
            background-color: #fff;
            font-size: 16px;
            font-family: "Rubik-Medium";
            background: -webkit-linear-gradient(
              90deg,
              #006fbc -1.11%,
              #7f43ff 97.46%
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;

            &:focus {
              outline: none;
            }
          }

          button.react-calendar__tile.react-calendar__year-view__months__month {
            border: none;
            background-color: #fff;
            color: #000000;
            font-size: 16px;
            font-family: "Rubik-Regular";

            &:focus {
              outline: none;
            }
          }
        }
      }
      .react-calendar__decade-view {
        width: 100%;
        height: 100%;

        .react-calendar__decade-view__years {
          width: 100%;
          height: 100%;

          button.react-calendar__tile.react-calendar__tile--now.react-calendar__tile--hasActive.react-calendar__decade-view__years__year {
            border: none;
            background-color: #fff;
            font-size: 16px;
            font-family: "Rubik-Medium";
            background: -webkit-linear-gradient(
              90deg,
              #006fbc -1.11%,
              #7f43ff 97.46%
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;

            &:focus {
              outline: none;
            }
          }

          button.react-calendar__tile.react-calendar__decade-view__years__year {
            border: none;
            background-color: #fff;
            color: #000000;
            font-size: 16px;
            font-family: "Rubik-Regular";

            &:focus {
              outline: none;
            }
          }
        }
      }
      .react-calendar__century-view {
        height: 100%;
        width: 100%;

        .react-calendar__century-view__decades {
          height: 100%;
          width: 100%;

          button.react-calendar__tile.react-calendar__tile--now.react-calendar__tile--hasActive.react-calendar__century-view__decades__decade {
            border: none;
            background-color: #fff;
            font-size: 16px;
            font-family: "Rubik-Medium";
            background: -webkit-linear-gradient(
              90deg,
              #006fbc -1.11%,
              #7f43ff 97.46%
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;

            &:focus {
              outline: none;
            }
          }

          button.react-calendar__tile.react-calendar__century-view__decades__decade {
            border: none;
            background-color: #fff;
            color: #000000;
            font-size: 16px;
            font-family: "Rubik-Regular";

            &:focus {
              outline: none;
            }
          }
        }
      }
    }
  }
`;

export {EngagementTab,NewConnectDevice,CardContainer, EmployeeCard, EmployeeGrid, CommonContainer, EngagementContainer,ConnectDevice,ContentBody,
  SplitEngagementSection, HeaderTitle, NewUserCard, ChallengesContainer, TopChallengeContent,ViewDetailsBtn,CalendarContainer,
  TopChallenges, TopChallengesImage, NoData, BirthdayContainer, ButtonContainer, HeaderContainer, StyledHeader,
  ImageContainer,ThemeGrid,UpcomingThemeIcon, ThemeCard,DataThemeGrid,ProfileData,PointData,InitiativeButtonV2New,
  DataThemeGridNew,BirthdayContainerNew, ActiveCard,VideoCard,ColorData,NewCardContainer,NewRow1,NewTotalCount,
  NewCardDashboard,ColorLine,NewPointData,NewImgContainer, ChallengeCard,BodyPart,DaysData,MoreMonthData,SeeAll,ChallengeButton, HeaderContainerNew,InitiativeButton, RightButton, CommonContainerV2, NoDataV2, InitiativeButtonV2, ButtonWrapper, CardWrapper, SeeAllButton, EventCard};
